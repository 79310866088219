@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.menu-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6vh 20vw;
    height: 4vh;
}

.menu-bar-link {
    color: #ffffffdd;
    text-decoration: none;
    font-size: 3vh;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    /* text-shadow: 0 0 8px rgba(255, 255, 255, 0.8), 0 0 12px rgba(255, 255, 255, 0.5); */
    cursor: pointer;
    transition: text-shadow 0.3s ease, color 0.3s ease;
}

.menu-bar-link:hover {
    text-shadow: 0 0 15px rgba(255, 255, 255, 1), 0 0 25px rgba(255, 255, 255, 0.9);
    color: #ffffff;
}
