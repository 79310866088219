body {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #121212;
}

svg {
    width: 100%;
    height: auto;
    stroke: white;
    stroke-width: 2;
    fill: none;
}

#path {
    animation: draw 5s forwards;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
